<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="搜索">
                <a-input v-model="queryParam.keyword" allow-clear placeholder="请输入搜索信息" />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <span class="table-page-search-submitButtons">
                <a-button type="primary" @click="reload">查询</a-button>
                <a-button style="margin-left: 8px" @click="tableReset">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handle(null, 'add')">新建用户</a-button>
      </div>

      <s-table
        ref="table"
        size="default"
        :rowKey="record => record.id"
        :columns="columns"
        :data="loadData"
        :alert="true"
        :totalCount="totalCount"
        :scroll="defaultScroll"
        showPagination="auto"
      >
        <span class="myCol" slot="username" slot-scope="text">
          {{ text }}
        </span>
        <span slot="sex" slot-scope="text">
          {{ text === 0 ? '男' : '女' }}
        </span>
        <span slot="description" slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>
        <span slot="role" slot-scope="role, record">
          <a-tag color="blue" class="myPointer" v-for="(item, index) in record.roles" :key="index">{{
            item.name
          }}</a-tag>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handle(record, 'edit')">编辑</a>
            <!-- <a-divider type="vertical"></a-divider>
            <a @click="handle(record, 'del')">删除</a> -->
            <a-divider type="vertical"></a-divider>
            <a @click="handleReset(record)">重置密码</a>
          </template>
        </span>
      </s-table>

      <a-modal
        title="刪除"
        :visible="del.visible"
        :confirm-loading="del.confirmLoading"
        @ok="handleDeleteOk"
        @cancel="handleCancel('del')"
      >
        <p>{{ del.ModalText }}</p>
      </a-modal>

      <a-drawer title="查看角色" placement="right" width="800" :visible="drawerVisible" @close="handleCancel('role')">
        <a-spin :spinning="roleLoading">
          <a-table
            ref="roleTable"
            size="default"
            bordered
            :rowKey="record => record.id"
            :row-selection="{ selectedRowKeys: checkedKeys, onChange: onSelectChange }"
            :columns="roleColumns"
            :data-source="fetchroles"
            :loading="roleLoading"
            :pagination="maxPagination"
          >
            <a slot="name" slot-scope="text">
              {{ text }}
            </a>

            <span slot="id" slot-scope="id">
              <a-tag color="blue">{{ id }}</a-tag>
            </span>
          </a-table>
        </a-spin>
        <div style="position: relative; bottom: 0; width: 100%; height: 53px;"></div>
        <div
          :style="{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderTop: '1px solid #e8e8e8',
            padding: '10px 16px',
            textAlign: 'right',
            left: 0,
            background: '#fff',
            borderRadius: '0 0 4px 4px'
          }"
        >
          <a-button style="marginRight: 8px" @click="handleCancel('role')">
            取消
          </a-button>
          <a-button type="primary" :loading="saveroleLoading" @click="checkRolesOk">
            保存
          </a-button>
        </div>
      </a-drawer>

      <create-form ref="createModal" @reload="reload" />
      <reset-password ref="resetModal" />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { moment } from 'moment'
import { STable, Ellipsis } from '@/components'
import { roles } from '@/api/roles'
import { nobrokerQuery, saveByBack, delUsers, getUsers, getUsersRoles, editUsers, putUsersRoles } from '@/api/users'
import { uploadOssBatch } from '@/utils/upload'
import CreateForm from './modules/CreateUserForm'
import ResetPassword from './modules/ResetPassword'

const columns = [
  {
    title: '用户名',
    dataIndex: 'username',
    scopedSlots: { customRender: 'username' }
  },
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '电话',
    dataIndex: 'phone',
    scopedSlots: { customRender: 'phone' }
  },
  {
    title: '角色',
    dataIndex: 'role',
    scopedSlots: { customRender: 'role' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    fixed: 'right',
    scopedSlots: { customRender: 'action' }
  }
]

const roleColumns = [
  {
    title: '权限名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },
  {
    title: 'ID',
    dataIndex: 'id',
    scopedSlots: { customRender: 'id' }
  }
]

export default {
  name: 'User',
  components: {
    STable,
    Ellipsis,
    CreateForm,
    ResetPassword
  },
  data() {
    return {
      noticeTitle: '后台用户',
      columns,
      roleColumns,
      totalCount: 0,
      // create model
      visible: false,
      confirmLoading: false,
      mdl: null,
      avatar: [],
      // 删除 modal
      del: {
        ModalText: '您要删除这个用户吗？',
        visible: false,
        confirmLoading: false
      },
      // 查询参数
      queryParam: {},
      // 查看角色
      roleId: null,
      fetchroles: null,
      byIdroles: null,
      drawerVisible: false,
      roleLoading: false,
      saveroleLoading: false,
      checkedKeys: [],
      loadData: parameter => {
        const params = this.paramFormat(parameter)
        Object.assign(params, this.queryParam)
        return nobrokerQuery(params).then(res => {
          const result = this.resFormat(res)
          this.totalCount = result.totalCount
          return result
        })
      }
    }
  },
  watch: {},
  created() {},
  computed: {
    serial() {
      return this.$store.state.env.serial
    },
    host() {
      return this.$store.state.oss.host
    }
  },
  methods: {
    reload() {
      this.tableHome()
      this.$refs.table.refresh()
    },
    resetReload() {
      this.queryParam = {}
      this.reload()
    },
    handleReset(record) {
      const id = record.id
      this.$refs.resetModal.showModal(id)
    },
    handle(record, type = 'add') {
      const form = this.$refs.createModal.form
      switch (type) {
        case 'add':
          form.resetFields()
          this.mdl = null
          this.avatar = []
          this.$refs.createModal.showModal()
          break
        case 'edit':
          this.editInfo(record)
          break
        case 'del':
          this.mdl = { ...record }
          this.del.visible = true
          break
        case 'role':
          this.lookRoles(record)
          break
      }
    },
    handleCancel(type) {
      switch (type) {
        case 'modal':
          this.mdl = null
          this.$refs.createModal.handleCancel()
          break
        case 'role':
          this.drawerVisible = false
          this.checkedKeys = []
          break
        case 'del':
          this.del.visible = false
          break
        case 'delCancel':
          this.del.visible = false
          this.confirmLoading = false
          break
        default:
          this.mdl = null
          this.drawerVisible = false
          break
      }
    },
    resetTable(handle, msg = null, type = 'success') {
      const form = this.$refs.createModal.form
      switch (handle) {
        case 'add':
          if (!msg) {
            msg = '添加成功。'
          }
          this.confirmLoading = false
          // form.resetFields()
          this.$refs.createModal.handleCancel()
          break
        case 'edit':
          if (!msg) {
            msg = '修改成功。'
          }
          this.confirmLoading = false
          form.resetFields()
          this.$refs.createModal.handleCancel()
          break
        case 'del':
          if (!msg) {
            msg = '删除成功。'
          }
          this.confirmLoading = false
          this.del.visible = false
          break
        case 'role':
          if (!msg) {
            msg = '修改用户角色成功。'
          }
          this.checkedKeys = []
          this.drawerVisible = false
          this.saveroleLoading = false
          break
        default:
          this.checkedKeys = []
          this.$refs.createModal.handleCancel()
          this.drawerVisible = false
          break
      }
      this.$refs.table.refresh()
      this.$notification.success({
        message: this.noticeTitle,
        description: msg
      })
    },
    handleOk() {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          const requestParameters = { ...values }
          if (requestParameters.birthday) {
            requestParameters.birthday = moment(new Date(requestParameters.birthday)).format('YYYY-MM-DD HH:mm:ss')
          }
          if (this.mdl) {
            if (this.avatar.length === 0) {
              this.patchUser(this.mdl.id, requestParameters)
            } else {
              this.fileListUpload(this.avatar, requestParameters, 'edit', this.mdl.id)
            }
          } else {
            if (this.avatar.length === 0) {
              this.newUser(requestParameters)
            } else {
              this.fileListUpload(this.avatar, requestParameters, 'add')
            }
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleDeleteOk(e) {
      this.del.ModalText = '将在两秒后删除此项。'
      this.del.confirmLoading = true
      setTimeout(() => {
        this.del.confirmLoading = false
        this.del.ModalText = '您要删除这个用户吗？'
        this.confirmLoading = true
        if (this.del.visible !== false) {
          const { mdl } = this
          delUsers(mdl.id)
            .then(res => {
              this.resetTable('del')
            })
            .catch(error => {
              this.resetTable('del', `${error}`, 'error')
            })
        } else {
          this.handleCancel('delCancel')
        }
      }, 2000)
    },
    getRowInfo(id, cb) {
      const param = { userId: id }
      getUsers(param).then(res => {
        console.log(res, 'this user')
        if (res.success) {
          cb(res.data)
        }
      })
    },
    editInfo(record) {
      this.getByIdRoles(record.id, res => {
        this.$refs.createModal.showModal(record)
      })
    },
    newUser(params) {
      saveByBack(params)
        .then(res => {
          setTimeout(() => {
            this.resetTable('add')
          }, 1000)
        })
        .catch(error => {
          this.resetTable('add', `${error}`, 'error')
        })
    },
    patchUser(id, params) {
      editUsers(id, params)
        .then(res => {
          setTimeout(() => {
            this.resetTable('edit')
          }, 1000)
        })
        .catch(error => {
          this.resetTable('edit', `${error}`, 'error')
        })
    },
    fileListUpload(fileList, params, title, id = '') {
      const mediaList = fileList.map(item => {
        if (item.originFileObj === undefined) {
          return item
        } else {
          return item.originFileObj
        }
      })
      uploadOssBatch(mediaList, '/Users/avatar/' + id).then(mediaRes => {
        if (mediaRes) {
          console.log(mediaRes, 'mediaRes')
          const urls = mediaRes
            .map(item => {
              if (item.path.indexOf('https') !== -1) {
                console.log(item.path.indexOf('https'), 'item.path.indexOf()')
                return this.host + '/Users/avatar' + item.path.slice(item.path.indexOf('avatar/') + 6)
              } else {
                return this.host + item.path
              }
            })
            .join('\n')
          console.log(urls, 'urls')
          params.avatar = urls
          switch (title) {
            case 'add':
              this.newUser(params)
              break
            case 'edit':
              this.patchUser(id, params)
              break
          }
        }
      })
    },
    /**
     * 角色管理
     */
    lookRoles(record) {
      const id = record.id
      this.roleLoading = true
      this.drawerVisible = true
      this.roleId = id
      this.getAllroles(rolesRes => {
        this.fetchroles = rolesRes
        this.getByIdRoles(id, byIdroleRes => {
          this.byIdroles = byIdroleRes
          this.checkedKeys = byIdroleRes.map(item => {
            return item.id
          })
        })
      })
    },
    // api
    getAllroles(cb) {
      const params = {
        page: 0,
        size: 1000,
        sort: 'createTime,desc'
      }
      roles(params).then(res => {
        cb(res._embedded.roles)
      })
    },
    getByIdRoles(id, cb) {
      getUsersRoles(id).then(res => {
        this.roleLoading = false
        cb(res._embedded.roles)
      })
    },
    // fn
    checkRolesOk() {
      this.saveroleLoading = true
      const { checkedKeys, roleId } = this
      const content = checkedKeys
        .map(item => {
          return this.serial + '/rest/roles/' + item
        })
        .join('\n')
      putUsersRoles(roleId, content)
        .then(res => {
          console.log(res, 'res')
          this.resetTable('role')
        })
        .catch(error => {
          this.resetTable('role', `${error}`, 'error')
        })
    },
    tableReset() {
      this.queryParam = {}
      this.$refs.table.refresh()
    },
    /**
     * Table
     *
     */
    onSelectChange(checkedKeys) {
      this.checkedKeys = checkedKeys
    }
  }
}
</script>

<style lang="less" scoped>
.myTree {
  /deep/ .ant-tree-treenode-switcher-close {
    margin: 15px 0 15px 0px;
  }
}
</style>
