<template>
  <a-modal 
    :title="noticeTitle" 
    :maskClosable="false" 
    :visible="visible" 
    :confirmLoading="loading" 
    @ok="handleOk" 
    @cancel="handleCancel">
    <p>确认为用户“{{ systemUser }}” 重置密码为手机号后六位？</p>
    <template slot="footer">
      <a-button key="back" @click="handleCancel">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk">确认</a-button>
    </template>
  </a-modal>
</template>
<script>
import { resetPassword, getUsers } from '@/api/users'
export default {
  name: 'ResetPassword',
  data() {
    return {
      noticeTitle: '重置密码',
      systemUser: null,
      systemUserId: null,
      loading: false,
      visible: false
    }
  },
  methods: {
    showModal(id) {
      this.visible = true
      this.loading = true
      this.systemUserId = id
      this.fetchInfo(id)
    },
    fetchInfo(id) {
      const param = { userId: id }
      getUsers(param)
        .then(res => {
          if (res.success) {
            this.systemUser = res.data.name
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    handleOk() {
      this.loading = true
      const param = {
        userId: this.systemUserId
      }
      resetPassword(param)
        .then(res => {
          this.loading = false
          this.visible = false
          this.$notification.success({
            message: this.noticeTitle,
            description: '重置成功'
          })
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleCancel() {
      this.loading = false
      this.visible = false
    }
  }
}
</script>
