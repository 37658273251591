<template>
  <a-modal
    title="用户"
    :width="modalWidth"
    :visible="visible"
    :confirmLoading="loading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <template slot="footer">
      <span class="sortDescription">用户默认密码为手机号后6位</span>
      <a-button key="back" @click="handleCancel">
        取消
      </a-button>
      <a-button key="submit" :loading="loading" type="primary" @click="handleOk">
        确定
      </a-button>
    </template>
    <a-spin :spinning="loading">
      <a-form :form="form" v-bind="formLayout">
        <!-- <a-form-item label="头像" style="margin-bottom: 0;">
          <uploadOss :media="media" :maxLength="1" @emitFiles="getMedia"></uploadOss>
        </a-form-item> -->
        <a-form-item label="用户名">
          <a-input
            allow-clear
            placeholder="请输入英文和数字组成的5到12个字符"
            v-decorator="['username', { rules: [{ required: true, message: '请输入用户名' }, { validator: userValidator }] }]"
          />
        </a-form-item>
        <a-form-item label="手机号">
          <a-input
            allow-clear
            placeholder="请输入手机号"
            v-decorator="[
              'phone',
              {
                rules: [{ required: true, message: '请输入11位手机号' }, { validator: validatePhone}]
              }
            ]"
            :maxLength="11"
          />
        </a-form-item>
        <a-form-item label="姓名">
          <a-input allow-clear placeholder="请输入姓名" v-decorator="['name']" :maxLength="15"></a-input>
        </a-form-item>
        <a-form-item label="角色">
          <a-select
            allow-clear
            mode="multiple"
            label-in-value
            placeholder="选择角色"
            style="width: 100%"
            @change="handleChange"
            option-label-prop="label"
            v-decorator="['roles', { rules: [{ required: true, message: '请选择角色' }] }]"
          >
            <a-select-option v-for="item in roleList" :key="item.value" :value="item.value" :label="item.name">
              {{ item.text }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="是否为驻场管理员">
          <a-radio-group v-decorator="['isLeader']">
            <a-radio :value="1">
              是
            </a-radio>
            <a-radio :value="0">
              否
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <!-- <a-form-item label="性别">
          <a-radio-group v-decorator="['sex', { initialValue: 0 }]">
            <a-radio :value="0">
              男
            </a-radio>
            <a-radio :value="1">
              女
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item label="籍贯">
          <a-input
            allow-clear
            placeholder="请输入籍贯"
            v-decorator="['birthplace']"
            :maxLength="15"
          />
        </a-form-item>
        <a-form-item label="民族">
          <a-input
            allow-clear
            placeholder="请输入民族"
            v-decorator="['nation']"
            :maxLength="15"
          />
        </a-form-item>
        <a-form-item label="学历">
          <a-input
            allow-clear
            placeholder="请输入学历"
            v-decorator="['education']"
            :maxLength="15"
          />
        </a-form-item>
        <a-form-item label="出生日期">
          <a-date-picker
            allow-clear
            v-decorator="['birthday']"
            @change="onchange"
            :maxLength="15"
          />
        </a-form-item>
        <a-form-item label="身份证">
          <a-input
            allow-clear
            placeholder="请输入身份证号"
            v-decorator="['idcard']"
            :maxLength="16"
          />
        </a-form-item>
        <a-form-item label="用户简介">
          <a-textarea
            allow-clear
            placeholder="请输入用户简介"
            :auto-size="{
              minRows: 8,
              maxRows: 10
            }"
            v-decorator="['description']"
            :maxLength="500"
          />
        </a-form-item> -->
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { moment } from 'moment'
import pick from 'lodash.pick'
import { UploadOss } from '@/components'
import { getUsers, saveByBack, getUsersRoles } from '@/api/users'
import { roles } from '@/api/roles'
import { userValidator } from '@/utils/util'
// 表单字段
const fields = [
  'name',
  // 'avatar',
  // 'birthday',
  // 'birthplace',
  // 'description',
  // 'education',
  // 'idcard',
  // 'nation',
  'phone',
  // 'sex',
  'username',
  'roles',
  'isLeader'
]

export default {
  props: {
    media: {
      type: Array,
      default: () => []
    }
  },
  components: {
    UploadOss
  },
  data() {
    return {
      noticeTitle: '用户管理',
      visible: false,
      loading: false,
      id: null,
      model: null,
      form: this.$form.createForm(this),
      roleList: []
    }
  },
  created() {
    // 防止表单未注册
    fields.forEach(v => this.form.getFieldDecorator(v))

    // 当 model 发生改变时，为表单设置值
    this.$watch('model', () => {
      this.model && this.form.setFieldsValue(pick(this.model, fields))
    })

    this.getRoles()
  },
  computed: {
    serial() {
      return this.$store.state.env.serial
    }
  },
  methods: {
    userValidator,
    getRoles() {
      const param = { ...this.maxPagination }
      roles(param).then(res => {
        this.roleList = res._embedded.roles.map(item => {
          return {
            name: item.name,
            text: `${item.name}`,
            value: item.name + ',' + item.id,
            code: item.code
          }
        })
      })
    },
    handleChange(value) {
      console.log(value)
      this.form.setFieldsValue({
        roles: value
      })
    },
    reload() {
      this.$emit('reload')
    },
    onchange(date, dateString) {
      console.log(dateString)
    },
    getMedia(data) {
      this.$emit('emitAvatar', data)
    },
    validatePhone(rules, value, callback) {
      const myReg = /^1[3456789]\d{9}$/
      if (value && !myReg.test(value)) {
        callback(new Error('请输入正确手机号'))
      }
      callback()
    },
    showModal(record = null) {
      if (record) {
        this.id = record.id
        this.loading = true
        this.visible = true
        this.getUserInfo()
      } else {
        this.visible = true
        this.model = null
      }
    },
    handleOk() {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          this.loading = true
          values.roles = values.roles.map(item => {
            const id = item.key.split(',')[1]
            const name = item.key.split(',')[0]
            return {
              id,
              name
            }
          })
          let param = { ...values }
          if (param.birthday) {
            param.birthday = moment(new Date(param.birthday)).format('YYYY-MM-DD HH:mm:ss')
          }
          if (this.model) {
            param = { ...param, id: this.id }
            this.newInfo(param, '编辑用户成功')
          } else {
            const roles = this.form.getFieldValue('roles').map(item => {
              const id = item.key.split(',')[1]
              const name = item.key.split(',')[0]
              return {
                id,
                name
              }
            })
            param = { ...param, roles }
            this.newInfo(param)
          }
        } else {
          this.loading = false
        }
      })
    },
    handleCancel() {
      this.visible = false
      this.loading = false
      this.model = null
      this.form.resetFields()
    },
    getUserInfo() {
      const param = { userId: this.id }
      getUsers(param).then(res => {
        if (res.success) {
          this.model = res.data
          this.fetachUserRole()
        }
      })
    },
    fetachUserRole() {
      getUsersRoles(this.id)
        .then(res => {
          console.log(res, 'roleres')
          const roles = res._embedded.roles.map(item => {
            return {
              key: `${item.name},${item.id}`,
              label: `${item.name}`
            }
          })
          this.form.setFieldsValue({
            roles
          })
        })
        .finally(() => {
          this.loading = false
        })
    },
    newInfo(params, msg = '新建用户成功') {
      console.log(msg, 'msg');
      setTimeout(() => {
        if (this.visible) {
          saveByBack(params)
            .then(res => {
              if (res.success) {
                this.handleCancel()
                this.reload()
                this.$notification.success({ message: this.noticeTitle, description: msg })
              }
            })
            .catch(() => {
              this.loading = false
            })
        }
      }, this.asyncTime)
    }
  }
}
</script>
